import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { motion } from 'framer-motion';

const Welcome = () => {
  const navigate = useNavigate();
  const [showButtons, setShowButtons] = useState(false);

  const languages = [
    { code: 'pl', name: 'Polski' },
    { code: 'ua', name: 'Українська' },
    { code: 'en', name: 'English' },
    { code: 'de', name: 'Deutsch' },
    { code: 'az', name: 'Azərbaycan' },
    { code: 'ru', name: 'Русский' }
  ];

  useEffect(() => {
    const timer = setTimeout(() => setShowButtons(true), 3000); // Zwiększono do 3 sekund
    return () => clearTimeout(timer);
  }, []);

  return (
    <div className="min-h-screen flex flex-col" 
         style={{
           backgroundImage: 'url("/back.png")',
           backgroundSize: 'cover',
           backgroundPosition: 'center'
         }}>
      <div className="flex-1" />
      
      <motion.div 
        className="flex-1 flex justify-center items-center"
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        transition={{ duration: 2 }}>
        <img 
          src="/logo.png" 
          alt="MS-Team Logo"
          className="w-4/5 md:w-3/4 lg:w-2/3"
        />
      </motion.div>

      <motion.div 
        className="flex-1 grid grid-cols-2 md:grid-cols-3 gap-4 p-8"
        initial={{ opacity: 0, y: 50 }}
        animate={{ opacity: showButtons ? 1 : 0, y: showButtons ? 0 : 50 }}
        transition={{ duration: 1, ease: "easeOut" }}>
        {languages.map((lang, index) => (
          <motion.button
            key={lang.code}
            onClick={() => navigate(`/data/${lang.code}`)}
            className="relative px-6 py-3 text-lg font-semibold rounded-lg 
                     text-yellow-500 overflow-hidden hover:scale-105
                     transition-all duration-300 border border-yellow-500"
            style={{
              backgroundImage: 'url("/back.png")',
              backgroundSize: 'cover',
              backgroundPosition: 'center'
            }}
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: showButtons ? 1 : 0, y: showButtons ? 0 : 20 }}
            transition={{ duration: 0.5, delay: index * 0.1 }}
          >
            {lang.name}
          </motion.button>
        ))}
      </motion.div>
    </div>
  );
};

export default Welcome;