import React from 'react';
import { useParams } from 'react-router-dom';
import { motion } from 'framer-motion';

const Data = () => {
  const { lang } = useParams();
  
  const translations = {
    pl: {
      mainText: "Profesjonalny zespół, który rozumie, że każda sytuacja jest wyjątkowa. Zapewniamy spersonalizowaną, kompleksową pomoc w kontaktach z polskimi urzędami, rozliczeniach PIT (w tym zagraniczne), złożeniu wniosków i inne.",
      address: "Siechnice 55-011, Polska"
    },
    ua: {
      mainText: "Професійна команда, яка розуміє, що кожна ситуація унікальна. Ми надаємо персоналізовану, комплексну допомогу у спілкуванні з польськими установами, оформленні PIT (включаючи міжнародні), поданні заяв та інших формальностях.",
      address: "Шехніце 55-011, Польща"
    },
    en: {
      mainText: "A professional team that understands every situation is unique. We provide personalized, comprehensive assistance in dealing with Polish offices, PIT settlements (including international), application submissions and other formalities.",
      address: "Siechnice 55-011, Poland"
    },
    de: {
      mainText: "Ein professionelles Team, das versteht, dass jede Situation einzigartig ist. Wir bieten personalisierte, umfassende Unterstützung im Umgang mit polnischen Ämtern, PIT-Abrechnungen (einschließlich international), Antragseinreichungen und anderen Formalitäten.",
      address: "Siechnice 55-011, Polen"
    },
    az: {
      mainText: "Hər vəziyyətin unikal olduğunu başa düşən peşəkar komanda. Polşa idarələri ilə əlaqələrdə, PIT hesablamalarında (beynəlxalq daxil olmaqla), ərizələrin təqdim edilməsində və digər rəsmiyyətlərdə fərdi, hərtərəfli yardım göstəririk.",
      address: "Siechnice 55-011, Polşa"
    },
    ru: {
      mainText: "Профессиональная команда, которая понимает, что каждая ситуация уникальна. Мы предоставляем персонализированную, комплексную помощь в контактах с польскими учреждениями, оформлении PIT (включая международные), подаче заявлений и других формальностях.",
      address: "Шехнице 55-011, Польша"
    }
  };

  const contacts = [
    { type: 'phone', value: '+48 531 488 170', icon: '📞', link: 'tel:+48531488170' },
    { type: 'email', value: 'contact@ms-team.eu', icon: '✉️', link: 'contact@ms-team.eu' },
    { type: 'viber', value: 'Viber', icon: '💬', link: 'viber://chat?number=%2B380955551100' },
    { type: 'whatsapp', value: 'WhatsApp', icon: '📱', link: 'https://wa.me/48531488170' },
    { type: 'address', value: translations[lang]?.address || translations.en.address, icon: '📍', 
      link: 'https://maps.google.com/maps?q=Siechnice+55-011,+Poland' }
  ];

  return (
    <div className="min-h-screen flex flex-col"
         style={{
           backgroundImage: 'url("/back.png")',
           backgroundSize: 'cover',
           backgroundPosition: 'center'
         }}>
      <motion.div 
        className="flex-1 flex justify-center items-center"
        initial={{ opacity: 0, y: -50 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ duration: 2 }}>
        <img 
          src="/logo.png" 
          alt="MS-Team Logo"
          className="w-1/2 md:w-2/5"
        />
      </motion.div>

      <div className="flex-1 flex justify-center items-center px-8">
        <p className="text-lg md:text-xl text-center max-w-4xl text-yellow-500">
          {translations[lang]?.mainText || translations.en.mainText}
        </p>
      </div>

      <div className="flex-1 flex flex-col items-center justify-center gap-4 px-8">
        {contacts.map(contact => (
          <a
            key={contact.type}
            href={contact.link}
            className="flex items-center gap-3 text-lg hover:scale-105 
                     transition-transform duration-300 text-yellow-500"
          >
            <span className="text-2xl">{contact.icon}</span>
            {contact.value}
          </a>
        ))}
      </div>
    </div>
  );
};

export default Data;